/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Spin } from 'antd'
import loadable from '@loadable/component'

const ContractPage = loadable(() => import('./marketing/contract'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const TempContractPage = loadable(() => import('./marketing/temp-contract'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const DeliverPage = loadable(() => import('./marketing/deliver'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
const SaleOrderByKisPage = loadable(() => import('./saleOrderByKis'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})
// 营销计划
const PlanPage = loadable(() => import('./marketing/plan'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

//回款账期管理
const PaymentTermPage = loadable(() => import('./payMentTerm'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

const TenderPages = loadable(() => import('./tenderManagement/router'), {
    fallback: (
        <div className="global-empty-loading-page">
            <Spin />
        </div>
    )
})

export const Contract = (props) => <ContractPage {...props} />
export const TempContract = (props) => <TempContractPage {...props} />
export const Deliver = (props) => <DeliverPage {...props} />
export const SaleOrderByKis = (props) => <SaleOrderByKisPage {...props} />
export const Plan = (props) => <PlanPage {...props} />
export const PaymentTerm = (props) => <PaymentTermPage {...props} />
export const Tender = (props) => <TenderPages {...props} />
