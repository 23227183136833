import { Contract, SaleOrderByKis, Plan, PaymentTerm, Tender, TempContract } from './loader'
import accountPeriodRoutes from './accountPeriod/routes'

const mMRouters = [
    {
        path: '/marketing-management/marketing/contract',
        exact: false,
        component: Contract
    },
    {
        path: '/marketing-management/temp-contract',
        exact: false,
        component: TempContract
    },
    {
        path: '/marketing-management/marketing/plan',
        exact: true,
        component: Plan
    },
    {
        path: '/marketing-management/wms/saleOrderKis',
        exact: true,
        component: SaleOrderByKis
    },
    {
        path: '/marketing-management/payment-term',
        exact: true,
        component: PaymentTerm
    },
    {
        path: '/marketing-management/tender',
        exact: false,
        component: Tender
    },
    ...accountPeriodRoutes
]
export default mMRouters
