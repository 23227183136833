//cSpell:word ceshibaogao wanzhengliucheng shengchanrenwudan shengchanjihua jihua
export const pMMenu = [
    {
        name: '生产计划',
        icon: 'icon-shengchanjihua-01',
        children: [{ name: '生产计划' }, { name: '投料计划' }]
    },
    {
        name: '生产订单',
        icon: 'icon-a-text_snippet_black_24dp4'
    },
    {
        name: '排程管理',
        icon: 'icon-paichengguanli-01',
        children: [
            {
                name: '工序组'
            },
            {
                name: '信息录入'
            },
            {
                name: '生产排程'
            }
        ]
    },
    {
        name: '调试排程',
        icon: 'icon-paichengguanli-01',
        children: [
            {
                name: '调试计划'
            },
            {
                name: '工序组'
            },
            {
                name: '信息录入'
            },
            {
                name: '调试报表'
            }
        ]
    },
    {
        name: '包装排程',
        icon: 'icon-paichengguanli-01',
        children: [
            {
                name: '包装计划'
            },
            {
                name: '工序组'
            },
            {
                name: '信息录入'
            },
            {
                name: '包装报表'
            }
        ]
    },
    {
        name: '设备流程',
        icon: 'icon-shebeiliucheng-01'
    },
    {
        name: '生产装配',
        icon: 'icon-view_in_ar_black_24dp',
        children: [
            {
                name: '物料绑定'
            },
            {
                name: '已装配设备'
            },
            {
                name: '出厂配置',
                icon: 'icon-auto_awesome_mosaic_black_24dp2'
            }
        ]
    },
    {
        name: '调试标准',
        icon: 'icon-rongqi'
    },
    {
        name: '生产调试',
        icon: 'icon-a-text_snippet_black_24dp5',
        children: [
            {
                name: '调试批次'
            },
            {
                name: '调试分组'
            },
            {
                name: 'GPS测试'
            },
            {
                name: '高低温测试'
            },
            {
                name: '标定测试'
            },
            {
                name: '工频测试'
            },
            {
                name: '功能测试'
            },
            {
                name: '行波测试'
            },
            {
                name: '老化测试'
            },
            {
                name: '小电流启动'
            },
            {
                name: '行波电场'
            }
        ]
    },
    {
        name: '调试报告',
        icon: 'icon-ceshibaogao-01'
    },
    {
        name: '问题设备',
        icon: 'icon-error_outline_black_24dp'
    },
    {
        name: '完整流程',
        icon: 'icon-wanzhengliucheng-01'
    },
    {
        name: '质检管理',
        icon: 'icon-a-text_snippet_black_24dp6',
        children: [
            {
                name: '外观检测'
            },
            {
                name: '来料检测'
            },
            {
                name: '半成品检测'
            },
            {
                name: '质检设备'
            },
            {
                name: '质检报告'
            }
        ]
    },
    {
        name: '物料管理',
        icon: 'icon-wuliaobangding-01'
    }
]
